.primary-red{
    background-color: #ef476f;
    color: antiquewhite;
}

.primary-yellow{
    background-color: #ffd166;
    color: black;
}

.primary-light-yellow{
    background-color: #ffe3a2;
    color: black;
}

.primary-green{
    background-color: #06d6a0;
    color: blantiquewhiteack;
}

.primary-blue{
    background-color: #118ab2;
    color: antiquewhite;
}

.primary-dark{
    background-color: #073b4c;
    color: antiquewhite;
}

.border-bottom {
    border: 0px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.off-dark {
    color: #4d4f52;
}

.bg-light-blue {
    background-color: #cfe5f9 !important;
}

.bg-extra-light-blue {
    background-color: #e9f4ff !important;
}

.bg-light-orange {
    background-color: #ece4db !important;
}

.bg-extra-light-orange {
    background-color: #f6f4d2 !important;
}

.bg-offwhite {
    background-color: #f8f9fa !important;
}

.bg-yellow {
    background-color: #fccc5d !important;
}

.bg-light-grey {
    background-color: #e7edf3 !important;
}
.bg-grey {
    background-color: #e4eaee !important;
}

.pointer-cursor {
    cursor: pointer;
}

.min-height-150{
    min-height: 150px;
}

.padding-bottom-100{
    padding-bottom: 100px;
}

.pointer-cursor-with-hover {
    cursor: pointer;
}
.pointer-cursor-with-hover:hover {
    background-color: #f0ce7e;
}
.padding-x-less{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}
@media (max-width: 500px) {
    .no-mobile-padding{
        padding-left: 0px !important;
    }
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: $placeholder-opacity-max;
  
    &.btn::before {
      display: inline-block;
      content: "";
    }
  }
  
  // Sizing
  .placeholder-xs {
    min-height: .6em;
  }
  
  .placeholder-sm {
    min-height: .8em;
  }
  
  .placeholder-lg {
    min-height: 1.2em;
  }
  
  // Animation
  .placeholder-glow {
    .placeholder {
      animation: placeholder-glow 2s ease-in-out infinite;
    }
  }
  
  @keyframes placeholder-glow {
    50% {
      opacity: $placeholder-opacity-min;
    }
  }
  
  .placeholder-wave {
    mask-image: linear-gradient(130deg, $black 55%, rgba(0, 0, 0, (1 - $placeholder-opacity-min)) 75%, $black 95%);
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite;
  }
  
  @keyframes placeholder-wave {
    100% {
      mask-position: -200% 0%;
    }
  }
  
  //Two verification Page
.verification {
    input {
    width: 50px !important;
    margin-bottom: 8px !important;
      &:focus {
        border: none;
        outline: none !important;
      }
    }
  }
  
  @media (max-width: 768px) {
    .verification {
      input {
        width: 40px !important;
      }
    }
  }
  
  @media (max-width: 320px) {
    .verification {
      input {
        width: 30px !important;
        height: 30px !important;
        font-size: 15px !important;
      }
    }
  }
  
  //Two verification Page - 2
  .verification-2 {
    input {
      &:focus {
        border: none;
        outline: none !important;
      }
    }
  }
  
  @media (max-width: 768px) {
    .verification-2 {
      input {
        width: 40px !important;
        font-size: 14px;
      }
    }
  }
  
  @media (max-width: 320px) {
    .verification-2 {
      input {
        width: 30px !important;
        height: 35px !important;
        font-size: 15px !important;
      }
    }
  }

//Ck Editor
.ck.ck-toolbar {
    background-color: $gray-200 !important;
    border: 1px solid $gray-300 !important;
  }
  
  .ck.ck-editor__main>.ck-editor__editable {
    border-color: $gray-300 !important;
    box-shadow: none !important;
  }
  
  .ck.ck-dropdown__panel,
  .ck.ck-list {
    background: $dropdown-bg !important;
    border: 1px solid $dropdown-border-color !important;
  }
  
  .ck-editor__editable {
    min-height: 245px !important;
  }
  
  .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
    flex-wrap: wrap !important;
  }
  
  .ck.ck-editor__main>.ck-editor__editable {
    background-color: $card-bg !important;
  }
  
  .ck.ck-reset_all,
  .ck.ck-reset_all * {
    color: $gray-600 !important
  }
  
  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background: transparent !important;
  }
  
  .ck.ck-button.ck-on,
  a.ck.ck-button.ck-on,
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover {
    background: $gray-200 !important;
  }
  
  